import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

/* 홈 */
// import Home from "./containers/home";
import Home from './re_containers/home';

/* 회사소개 */
import IntroCompany from './re_containers/introCompany';
import NewsCompany from './re_containers/newsCompany';
import CareerCompany from './re_containers/careerCompany';
import CareerDetailCompany from './re_containers/careerCompany/viewDetail';

/* 서비스 소개 */
import ServicesLawyer from './re_containers/servicesLawyer';
import ServicesJamoon from './re_containers/servicesJamoon';
import ServicesLoan from './re_containers/servicesLoan';
import ServicesDiag from './re_containers/servicesDiag';

/* Contact us */
import ContactInvest from './re_containers/contactInvest';
import ContactLink from './re_containers/contactLink';
import ContactService from './re_containers/contactService';

const App = (props) => {
  return (
    <Router>
      <Switch>
        {/* 메인,메뉴,소식 */}
        <Route
          path="/"
          exact
          render={(propsHome) => <Home {...propsHome} />}
        />
        <Route
          path="/company/introduce"
          exact
          render={(propsHome) => (
            <IntroCompany {...propsHome} />
          )}
        />
        <Route
          path="/company/news"
          exact
          render={(propsHome) => (
            <NewsCompany {...propsHome} />
          )}
        />
        <Route
          path="/company/career"
          exact
          render={(propsHome) => (
            <CareerCompany {...propsHome} />
          )}
        />
        <Route
          path="/company/career/detail"
          exact
          render={(propsHome) => (
            <CareerDetailCompany {...propsHome} />
          )}
        />

        {/* 서비스 */}
        <Route
          path="/services/lawyer"
          exact
          render={(propsHome) => (
            <ServicesLawyer {...propsHome} />
          )}
        />
        <Route
          path="/services/jamoon"
          exact
          render={(propsHome) => (
            <ServicesJamoon {...propsHome} />
          )}
        />
        <Route
          path="/services/loan"
          exact
          render={(propsHome) => (
            <ServicesLoan {...propsHome} />
          )}
        />
        <Route
          path="/services/diagnosis"
          exact
          render={(propsHome) => (
            <ServicesDiag {...propsHome} />
          )}
        />
        <Route
          path="/contact/invest"
          exact
          render={(propsHome) => (
            <ContactInvest {...propsHome} />
          )}
        />
        <Route
          path="/contact/link"
          exact
          render={(propsHome) => (
            <ContactLink {...propsHome} />
          )}
        />
        <Route
          path="/contact/service"
          exact
          render={(propsHome) => (
            <ContactService {...propsHome} />
          )}
        />

        {/* 회사소개-대표인사말 */}
      </Switch>
    </Router>
  );
};

export default App;
