import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { isMobile } from 'react-device-detect';
// import Sidebar from "../../re_components/sidebar";
import HeaderM from '../../re_components/header_m';
import Company from '../../re_components/company';

import Header from '../../re_components/header';
import FooterM from '../../re_components/footer_m';
import Footer from '../../re_components/footer';
import { SlArrowRight } from 'react-icons/sl';
import { POST_DATA } from '../../re_components/company/mockData';

import '../../reset/reset.css';
import './main.scss';
import main from '../../assets/images/main.png';

const Index = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [isInitialRender, setIsInitialRender] =
    useState(true);
  const [topBtn, setTopBtn] = useState(false);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      setPosts(POST_DATA);
    }
  }, [isInitialRender, posts]);

  useEffect(() => {
    if (page >= 10) {
      setTopBtn(true);
    }
  }, [page]);

  const clickTopBtn = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTopBtn(false);
  };

  const renderHeader = () => {
    if (isMobile) return <HeaderM />;
    return <Header menu={1} />;
  };

  return (
    <>
      <div className="secondUp">
        {renderHeader()}
        <Company type="introduce" />
      </div>
      {!isMobile ? <Footer /> : <FooterM />}
    </>
  );
};

export default Index;
