import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

// import SideMenuM from "../sideMenu_m";
import SideMenuM from '../sideMenu_m';
import tMenu from '../../assets/images/t_menu.png';
import mLogoWide from '../../assets/images/logo_m.png';
import styles from './header_m.module.scss';

const HeaderM = () => {
  const history = useHistory();
  // const dispatch = useAppDispatch();
  // const usersState = useAppSelector((state) => state.users);

  const [openSideMenu, setOpenSideMenu] = useState(false);
  const [loginStatus, setLoginStatus] = useState({
    isLoggedIn: false,
    id: undefined,
    info: '',
    name: '',
    link: '',
    phone: '',
    signCheck: undefined,
  });
  const { isLoggedIn, id, info, name } = loginStatus;

  const onClickSideMenu = () => {
    setOpenSideMenu((prev) => !prev);
  };

  const onClickLogin = () => {
    history.push('/re_login');
  };

  return (
    <div className={styles.headerWrapper}>
      {openSideMenu && (
        <SideMenuM
          loginInfo={{
            isLoggedIn,
            // userInfo: usersState,
          }}
          onClickSideMenu={onClickSideMenu}
        />
      )}
      <div className={styles.menuButtonWrapper}>
        <button type="button" onClick={onClickSideMenu}>
          <img
            className={styles.menu}
            src={tMenu}
            alt="menu button"
          />
        </button>
      </div>
      <div className={styles.logoWrapper}>
        {/* <Link to={usersState.info === "lawyer" ? "re_HomeLawyer" : "/"}> */}
        <Link to={'/'}>
          <button type="button">
            <img
              className={styles.logo}
              src={mLogoWide}
              alt="logo"
            />
          </button>
        </Link>
      </div>
      {/* <div className={styles.loginButtonWrapper}>
        <button type="button" onClick={onClickLogin}>
          {isLoggedIn ? "로그아웃" : "로그인"}
        </button>
      </div> */}
    </div>
  );
};

export default HeaderM;
