import React, {
  SetStateAction,
  Dispatch,
  useEffect,
} from 'react';
import styles from './pagination.module.scss';

const Pagination = ({
  allPageCount,
  curPage,
  setCurPage,
}) => {
  const PAGE_COUNT_PER_PAGE = 10;
  const lastPagesResult = Math.floor(
    allPageCount / PAGE_COUNT_PER_PAGE
  );
  const lastPagesRemainder =
    allPageCount % PAGE_COUNT_PER_PAGE;

  const displayedPages = () => {
    const result = Math.floor(curPage / 10);
    const remainder = curPage % PAGE_COUNT_PER_PAGE;

    if (curPage === 1) {
      const pageList = Array(PAGE_COUNT_PER_PAGE)
        .fill(1)
        .map((el, i) => el + i);
      return pageList;
    }
    if (curPage <= PAGE_COUNT_PER_PAGE) {
      if (remainder === 0) {
        const pageList = Array(PAGE_COUNT_PER_PAGE)
          .fill((result - 1) * PAGE_COUNT_PER_PAGE + 1)
          .map((el, i) => el + i);
        return pageList;
      }
      const pageList = Array(PAGE_COUNT_PER_PAGE)
        .fill(result * PAGE_COUNT_PER_PAGE + 1)
        .map((el, i) => el + i);
      return pageList;
    }
    if (curPage > PAGE_COUNT_PER_PAGE) {
      if (
        Math.floor(curPage / PAGE_COUNT_PER_PAGE) ===
        lastPagesResult
      ) {
        if (remainder === 0) {
          const pageList = Array(PAGE_COUNT_PER_PAGE)
            .fill((result - 1) * PAGE_COUNT_PER_PAGE + 1)
            .map((el, i) => el + i);
          return pageList;
        }
        const lastPageList = Array(lastPagesRemainder)
          .fill(result * PAGE_COUNT_PER_PAGE)
          .map((el, i) => el + i + 1);
        return lastPageList;
      }

      if (curPage !== allPageCount) {
        if (remainder === 0) {
          const pageList = Array(PAGE_COUNT_PER_PAGE)
            .fill((result - 1) * PAGE_COUNT_PER_PAGE + 1)
            .map((el, i) => el + i);
          return pageList;
        }

        const pageList = Array(PAGE_COUNT_PER_PAGE)
          .fill(result * PAGE_COUNT_PER_PAGE + 1)
          .map((el, i) => el + i);
        return pageList;
      }

      const pageList = Array(remainder)
        .fill(result * PAGE_COUNT_PER_PAGE)
        .map((el, i) => el + i + 1);
      return pageList;
    }

    return undefined;
  };

  const pages =
    allPageCount > PAGE_COUNT_PER_PAGE
      ? displayedPages()
      : Array.from(
          { length: allPageCount > 1 ? allPageCount : 1 },
          (v, i) => (allPageCount > 1 ? i + 1 : 1)
        );

  useEffect(() => {
    displayedPages();
  }, [, curPage]);

  const onClickPageNumber = (e) => {
    const { pageIndex } = e.currentTarget.dataset;
    const pageIndexToNumber = Number(pageIndex);
    setCurPage(pageIndexToNumber);
  };

  const onClickPrevTenPage = () => {
    if (curPage - 10 > 0) setCurPage((prev) => prev - 10);
    else if (curPage <= 1) setCurPage(1);
    else setCurPage((prev) => prev - 1);
  };

  const onClickNextTenPage = () => {
    if (curPage + 10 < allPageCount)
      setCurPage((prev) => prev + 10);
    else if (allPageCount <= 10) {
      if (curPage >= allPageCount) setCurPage(allPageCount);
      else setCurPage((prev) => prev + 1);
    }
  };

  const onClickFirstPage = () => {
    if (allPageCount > 0) setCurPage(1);
  };

  const onClickLastPage = () => {
    if (allPageCount > 0) setCurPage(allPageCount);
  };

  return (
    <div className={styles.paginationWrapper}>
      <button
        type="button"
        className={styles.blackBox}
        onClick={onClickFirstPage}
      >
        &lt;&lt;
      </button>
      <button
        type="button"
        className={styles.blackBox}
        onClick={onClickPrevTenPage}
      >
        &lt;
      </button>
      {pages?.map((el) => (
        <button
          type="button"
          key={el}
          className={
            el === curPage
              ? `${styles.pageNumber} ${styles.active}`
              : styles.pageNumber
          }
          data-page-index={el}
          onClick={onClickPageNumber}
        >
          {el}
        </button>
      ))}
      <button
        type="button"
        className={styles.blackBox}
        onClick={onClickNextTenPage}
      >
        &gt;
      </button>
      <button
        type="button"
        className={styles.blackBox}
        onClick={onClickLastPage}
      >
        &gt;&gt;
      </button>
    </div>
  );
};

export default Pagination;
