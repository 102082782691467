import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';

import getMasterNewsList from '../../services/getMasterNewsList';
// import styles from "./style.module.scss";
// import  "./re_header.scss";
// import "../../reset/reset.css"
import styles from './services.module.scss';
import formatDate from '../../utils/formatDate';
import Pagination from '../pagination/pagination';

import map from '../../assets/images/map.jpg';
import mc from '../../assets/images/mc.png';
import jm from '../../assets/images/jm.png';
import zd from '../../assets/images/zd.png';
import ms from '../../assets/images/ms.png';
import loan from '../../assets/images/loan.png';
import gs from '../../assets/images/gs.png';
import { NEWS_DATA } from './mockData';

const Index = ({ type }) => {
  // const { params } = useParams();
  const location = useLocation();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [title, setTitle] = useState('lawyer');

  const [curPage, setCurPage] = useState(1);
  // const [allPageCount, setAllPageCount] = useState(0);
  // const [news, setNews] = useState([]);
  const [news, setNews] = useState(NEWS_DATA);
  const [allPageCount, setAllPageCount] = useState(2);

  const [textCount, setTextCount] = useState(0);

  const [userInput, setUserInput] = useState({
    title: '',
    type: '',
    name: '',
    phone: '',
    email: '',
    content: '',
    file: '',
    fileName: '',
  });

  let words = location.pathname.split('/');
  useEffect(() => {
    if (location) {
      switch (words[2]) {
        case 'lawyer':
          setTitle('lawyer');
          break;
        case 'jamoon':
          setTitle('jamoon');
          break;
        case 'loan':
          setTitle('loan');
          break;
        case 'diagnosis':
          setTitle('diagnosis');
          break;
        default:
          break;
      }
    }
  }, [location]);

  const onClickCategory = (e) => {
    const { mainType } = e.currentTarget.dataset;
    setTitle(mainType);
  };

  const onChangeInput = (e) => {
    // eslint-disable-next-line
    const { name, value } = e.currentTarget;
    setUserInput({ ...userInput, [name]: value });
    countText(name, value.length);
  };

  const countText = (inputName, inputLength) => {
    if (inputName === 'content') {
      setTextCount(inputLength);
    }
  };

  const onChangeFile = (e) => {
    if (e.target.files !== null) {
      const file = e.target.files[0];
      setUserInput({
        ...userInput,
        file,
        fileName: file.name,
      });
    }
  };

  const onClickSubmit = () => {
    alert('현재 지원하지 않는 서비스 입니다.');
    // insertPartnershipContent();
  };

  return (
    <>
      <div className={styles.companyHeader}>
        <h1>SERVICES</h1>
        <p>
          로스닥은 의뢰인의 문제 해결을 위해 상황에 맞는
          법률서비스를 제공하고 있습니다.
        </p>
      </div>
      <div className={styles.companySection}>
        <ul className={styles.companyMenu}>
          <li>
            <button
              type="button"
              data-main-type="lawyer"
              onClick={onClickCategory}
              className={
                title === 'lawyer'
                  ? styles.active
                  : styles.off
              }
            >
              변호사 선임
            </button>
          </li>
          <li>
            <button
              type="button"
              data-main-type="jamoon"
              onClick={onClickCategory}
              className={
                title === 'jamoon'
                  ? styles.active
                  : styles.off
              }
            >
              사건자문서
            </button>
          </li>
          <li>
            <button
              type="button"
              data-main-type="loan"
              onClick={onClickCategory}
              className={
                title === 'loan'
                  ? styles.active
                  : styles.off
              }
            >
              소송비용 대출 연계
            </button>
          </li>
          <li>
            <button
              type="button"
              data-main-type="diagnosis"
              onClick={onClickCategory}
              className={
                title === 'diagnosis'
                  ? styles.active
                  : styles.off
              }
            >
              승소 가능성 진단
            </button>
          </li>
        </ul>
        <div className={styles.companyBox}>
          {title === 'lawyer' && (
            <div className={styles.servicesIntro}>
              <ul>
                <li>
                  <img
                    className={styles.servicesImg}
                    src={mc}
                    alt="map"
                  />
                  <p>
                    <span className={styles.contentsRed}>
                      {' '}
                      최고 승소확률{' '}
                    </span>{' '}
                    변호사 선임
                  </p>
                  <pre>
                    로스닥의 최고 승소확률변호사 선임
                    서비스는 자연어 처리된 과거 승소사례를
                    AI가 의뢰인의 사건요지서와 비교하여
                    유사율 순으로 최적의 변호사를 의뢰인에게
                    추천하는 시스템
                  </pre>
                </li>
                <li>
                  <img
                    className={styles.servicesImg}
                    src={gs}
                    alt="map"
                  />
                  <p>
                    <span className={styles.contentsRed}>
                      {' '}
                      최고 가성비{' '}
                    </span>{' '}
                    변호사 선임
                  </p>
                  <pre>
                    수임료 가격 경쟁을 통한 합리적
                    선임비용을 실현한 서비스로써, 변호사
                    선임 및 계약방식에 대한 기술 특허를
                    취득한, 로스닥만의 특화된 서비스
                  </pre>
                </li>
                <li>
                  <img
                    className={styles.servicesImg}
                    src={ms}
                    alt="map"
                  />
                  <p>
                    <span className={styles.contentsRed}>
                      {' '}
                      소액민사
                    </span>
                    사건 소송
                  </p>
                  <pre>
                    소송물가액 3천만원 미만의 민사 소송인
                    경우, 최소의 소송 비용으로 변호사의
                    조력을 받을 수 있는 법률 서비스
                  </pre>
                </li>
                <li>
                  <img
                    className={styles.servicesImg}
                    src={zd}
                    alt="map"
                  />
                  <p>
                    <span className={styles.contentsRed}>
                      집단
                    </span>{' '}
                    소송
                  </p>
                  <pre>
                    의뢰인이 속한 집단에 다수의 피해가
                    발생했을 경우, 피해의 당사자가 해당
                    사건에 관심을 갖는 변호인을 선택하여
                    소송을 진행 할 수 있다.
                  </pre>
                </li>

                {/* <li>
                    <img className={styles.servicesImg} src={loan} alt="map" />
                    <p><span className={styles.contentsRed}> 소송비용 대출</span>연계 시스템</p>
                    <pre>
                    소송을 진행 할 경우 승소의 가능성은 높지만, 변호사 선임비용이
                    부담스러운 경우, 로스닥의 소송비용대출 연계를 활용하여 
                    저금리로 소송비용을 대출 받을 수 있도록 도와드립니다.<br/>
                    대출금의 상환은 패소한 상대방이 소송비용보전의 명령에 의해  
                    전달된 소송비용으로  완납하여 마무리 합니다.
                    </pre>
                  </li> */}
              </ul>
              {/* <ul className={styles.addBox}>
                      <li>
                          <img className={styles.servicesImg} src={zd} alt="map" />
                          <p><span className={styles.contentsRed}>집단</span> 소송</p>
                          <pre>수임료 가격 경쟁을 통한 합리적 선임비용을 실현한 서비스로써,  
                          변호사 선임 및 계약방식에 대한 기술 특허를 취득한, 로스닥만의 
                          특화된 서비스</pre>
                      </li>
                </ul> */}
            </div>
          )}
          {title === 'jamoon' && (
            <div className={styles.servicesIntro}>
              <ul>
                <li>
                  <img
                    className={styles.servicesImg}
                    src={jm}
                    alt="map"
                  />
                  <p>
                    <span className={styles.contentsRed}>
                      변호사 자문서
                    </span>{' '}
                    서비스
                  </p>
                  <pre>
                    의뢰인의 사건요지서를 검토한 변호사가
                    사건의 내용을 파악하여 적절한 소송에
                    대한 조언을 의뢰인에게 문서로 전달
                    함으로써, 소송을 진행 하는데 도움을 줄
                    수 있습니다.
                  </pre>
                </li>
              </ul>
            </div>
          )}
          {title === 'loan' && (
            <div className={styles.servicesIntro}>
              <ul>
                <li>
                  <img
                    className={styles.servicesImg}
                    src={loan}
                    alt="map"
                  />
                  <p>
                    <span className={styles.contentsRed}>
                      {' '}
                      소송비용 대출
                    </span>
                    연계 시스템
                  </p>
                  <pre>
                    소송을 진행 할 경우 승소의 가능성은
                    높지만, 변호사 선임비용이 부담스러운
                    경우, 로스닥의 소송비용대출 연계를
                    활용하여 저금리로 소송비용을 대출 받을
                    수 있도록 도와드립니다.
                    <br />
                    대출금의 상환은 패소한 상대방이
                    소송비용보전의 명령에 의해 전달된
                    소송비용으로 완납하여 마무리 합니다.
                  </pre>
                </li>
              </ul>
            </div>
          )}
          {title === 'diagnosis' && (
            <div className={styles.servicesIntro}>
              <ul>
                <li>
                  <img
                    className={styles.servicesImg}
                    src={mc}
                    alt="map"
                  />
                  <p>
                    <span className={styles.contentsRed}>
                      승소 가능성{' '}
                    </span>{' '}
                    진단
                  </p>
                  <pre>
                    AI가 빅데이터의 승소사례와 의뢰인이
                    사건내용에 대한 유사율을 분석하여 승소
                    가능성을 지표로 도출하고, 분석자료는
                    소송비용 대출을 신청하는 경우 금융사에
                    제공되어 승소 가능성이 높은 경우에
                    대출금리 인하 요건이 됩니다.{' '}
                  </pre>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
