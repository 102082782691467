import React, { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';

import styles from './careerDetail.module.scss';

import {
  NEWS_DATA,
  ENGINEER_DATA,
  MARKETING_DATA,
} from '../../company/mockData';

const Index = () => {
  // const { params } = useParams();
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const history = useHistory();
  const location = useLocation();

  const { data } = location.state;


  const [title, setTitle] = useState('introduce');

  const [userInput, setUserInput] = useState({
    title: '',
    name: '',
    phone: '',
    email: '',
    content: '',
    type: '정규직',
    caseType1: '',
    caseType2: '',
    // file: "",
    // fileName: "",
  });

  const selCategoryList = [
    // "필수항목 (대분류)",
    '정규직',
    '계약직',
    '파견직',
  ];
  const selAppyList = [
    '필수항목 (대분류)',
    '개발',
    '마케팅',
  ];

  // useEffect(() =>{
  //   if (isMobile) {
  //     window.scrollTo({
  //       top: 0,
  //       left: 0,
  //     });
  //   } else {
  //     window.scrollTo({
  //       top: 832,
  //       left: 0,
  //     });
  //   }
  // },[data])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  let words = location.pathname.split('/');
  useEffect(() => {
    if (location) {
      switch (words[2]) {
        case 'introduce':
          setTitle('introduce');
          break;
        case 'news':
          setTitle('news');
          break;
        case 'career':
          setTitle('career');
          break;
        default:
          break;
      }
    }
  }, [location]);

  const onChangeInput = (e) => {
    // eslint-disable-next-line
    const { name, value } = e.currentTarget;
    setUserInput({ ...userInput, [name]: value });
    // countText(name, value.length);
  };

  const onClickSubmit = () => {
    // window.open(`https://www.saramin.co.kr/`, '_blank');
    window.open(`${data?.apply}`, '_blank');
    // data.apply
    // insertPartnershipContent();
    
  };

  const insertPartnershipContent = async () => {
    // const res = await customerCenterPartnershipAPI(userInput);
    // if (res.status === 200) {
    //   alert("제휴 문의가 등록되었습니다.");
    //   window.location.reload();
    // }
  };

  return (
    <div className={styles.partnershipInquiry}>
      <h1>입사 지원</h1>
      <ul className={styles.row_table}>
        <li className={styles.input_li}>
          <label
            htmlFor="name"
            className={styles.label_middle}
          >
            제목
          </label>
          <span>
            <input
              name="name"
              onChange={onChangeInput}
              value={data?.title}
              readOnly
            />
          </span>
        </li>
        <li className={styles.input_li}>
          <label
            htmlFor="phone"
            className={styles.label_middle}
          >
            모집분야
          </label>
          <span>
            <input
              name="phone"
              onChange={onChangeInput}
              value={data?.category}
              readOnly
            />
          </span>
        </li>
        <li className={styles.input_li}>
          <label
            htmlFor="email"
            className={styles.label_middle}
          >
            채용형태
          </label>
          <span>
            <input
              name="email"
              onChange={onChangeInput}
              value={data?.type}
              readOnly
            />
          </span>
        </li>
        <li className={styles.input_li}>
          <label
            htmlFor="email"
            className={styles.label_middle}
          >
            모집기간
          </label>
          <span>
            <input
              name="email"
              onChange={onChangeInput}
              value={data?.date}
              readOnly
            />
          </span>
        </li>
        <li className={styles.input_li}>
          <label
            htmlFor="email"
            className={styles.label_middle}
          >
            채용형태
          </label>
          <span>
            <input
              name="email"
              onChange={onChangeInput}
              value={data?.status}
              readOnly
            />
          </span>
        </li>
      </ul>
      <div className={styles.buttonWrapper}>
        <button
          type="button"
          className={styles.listBtn}
          onClick={onClickSubmit}
          // onClick={() => {
          //   window.location.href = "https://www.saramin.co.kr/";
          // }}
        >
          지원하러 가기
        </button>
      </div>
    </div>
  );
};

export default Index;
