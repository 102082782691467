import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';

import getMasterNewsList from '../../services/getMasterNewsList';
// import styles from "./style.module.scss";
// import  "./re_header.scss";
// import "../../reset/reset.css"
import styles from './contact.module.scss';
import formatDate from '../../utils/formatDate';
import Pagination from '../pagination/pagination';

import map from '../../assets/images/map.jpg';
import mc from '../../assets/images/mc.png';
import jm from '../../assets/images/jm.png';
import zd from '../../assets/images/zd.png';
import ms from '../../assets/images/ms.png';
import loan from '../../assets/images/loan.png';
import gs from '../../assets/images/gs.png';
import { NEWS_DATA } from './mockData';

const Index = ({ type }) => {
  // const { params } = useParams();
  const location = useLocation();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [title, setTitle] = useState('lawyer');

  const [curPage, setCurPage] = useState(1);
  // const [allPageCount, setAllPageCount] = useState(0);
  // const [news, setNews] = useState([]);
  const [news, setNews] = useState(NEWS_DATA);
  const [allPageCount, setAllPageCount] = useState(2);

  const [textCount, setTextCount] = useState(0);

  const [userInput, setUserInput] = useState({
    title: '',
    type: '',
    name: '',
    phone: '',
    email: '',
    content: '',
    file: '',
    fileName: '',
  });

  let words = location.pathname.split('/');
  useEffect(() => {
    if (location) {
      switch (words[2]) {
        case 'invest':
          setTitle('lawyer');
          break;
        case 'link':
          setTitle('jamoon');
          break;
        case 'service':
          setTitle('loan');
          break;
        default:
          break;
      }
    }
  }, [location]);

  const onClickCategory = (e) => {
    const { mainType } = e.currentTarget.dataset;
    setTitle(mainType);
  };

  const onChangeInput = (e) => {
    // eslint-disable-next-line
    const { name, value } = e.currentTarget;
    setUserInput({ ...userInput, [name]: value });
    countText(name, value.length);
  };

  const countText = (inputName, inputLength) => {
    if (inputName === 'content') {
      setTextCount(inputLength);
    }
  };

  const onChangeFile = (e) => {
    if (e.target.files !== null) {
      const file = e.target.files[0];
      setUserInput({
        ...userInput,
        file,
        fileName: file.name,
      });
    }
  };

  const onClickSubmit = () => {
    alert('현재 지원하지 않는 서비스 입니다.');
    // insertPartnershipContent();
  };

  return (
    <>
      <div className={styles.companyHeader}>
        <h1>CONTACT US</h1>
        <p>
          로스닥은 의뢰인의 문제 해결을 위해 상황에 맞는
          법률서비스를 제공하고 있습니다.
        </p>
      </div>
      <div className={styles.companySection}>
        <ul className={styles.companyMenu}>
          <li>
            <button
              type="button"
              data-main-type="lawyer"
              onClick={onClickCategory}
              className={
                title === 'lawyer'
                  ? styles.active
                  : styles.off
              }
            >
              투자 문의
            </button>
          </li>
          <li>
            <button
              type="button"
              data-main-type="jamoon"
              onClick={onClickCategory}
              className={
                title === 'jamoon'
                  ? styles.active
                  : styles.off
              }
            >
              사업 연계
            </button>
          </li>
          <li>
            <button
              type="button"
              data-main-type="loan"
              onClick={onClickCategory}
              className={
                title === 'loan'
                  ? styles.active
                  : styles.off
              }
            >
              서비스 문의
            </button>
          </li>
        </ul>
        <div className={styles.companyBox}>
          {title === 'lawyer' && (
            <div className={styles.servicesIntro}>
              <ul>
                <li>
                  {/* <img className={styles.servicesImg} src={mc} alt="map" /> */}
                  <p>
                    <span className={styles.contentsRed}>
                      투자{' '}
                    </span>
                    문의
                  </p>
                  <p className={styles.contents}>
                    로스닥에 투자를 원하시는 분은 아래로
                    연락 바랍니다.
                  </p>
                  <pre>invest@lawction.net</pre>
                </li>
              </ul>
              {/* <ul className={styles.addBox}>
                      <li>
                          <img className={styles.servicesImg} src={zd} alt="map" />
                          <p><span className={styles.contentsRed}>집단</span> 소송</p>
                          <pre>수임료 가격 경쟁을 통한 합리적 선임비용을 실현한 서비스로써,  
                          변호사 선임 및 계약방식에 대한 기술 특허를 취득한, 로스닥만의 
                          특화된 서비스</pre>
                      </li>
                </ul> */}
            </div>
          )}
          {title === 'jamoon' && (
            <div className={styles.servicesIntro}>
              <ul>
                <li>
                  {/* <img className={styles.servicesImg} src={jm} alt="map" /> */}
                  <p>
                    <span className={styles.contentsRed}>
                      사업
                    </span>{' '}
                    연계
                  </p>
                  <p className={styles.contents}>
                    로스닥과 협업 등 사업연계를 원하시는
                    분은 아래로 연락 바랍니다.
                  </p>
                  <pre>lawsdaq@lawction.net</pre>
                </li>
              </ul>
            </div>
          )}
          {title === 'loan' && (
            <div className={styles.servicesIntro}>
              <ul>
                <li>
                  {/* <img className={styles.servicesImg} src={loan} alt="map" /> */}
                  <p>
                    <span className={styles.contentsRed}>
                      서비스{' '}
                    </span>
                    문의
                  </p>
                  <p className={styles.contents}>
                    로스닥 이용에 불편한 사항이 있거나 추가
                    서비스를 원하시는 분은 아래로 연락
                    바랍니다
                  </p>
                  <pre>lawsdaq@lawction.net </pre>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
