import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { isMobile } from 'react-device-detect';
import HeaderM from '../../../re_components/header_m';
import Company from '../../../re_components/company';

import Header from '../../../re_components/header';
// import Footer from "../../components/footer";
import FooterM from '../../../re_components/footer_m';
import Footer from '../../../re_components/footer';
// import { POST_DATA } from "../../components/mainContentList_m/mockData";

import '../../../reset/reset.css';
import './careerDetail.scss';

const Index = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [isInitialRender, setIsInitialRender] =
    useState(true);
  const [topBtn, setTopBtn] = useState(false);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      // setPosts(POST_DATA);
    }
  }, [isInitialRender, posts]);

  // useEffect(() => {
  //   if (page >= 10) {
  //     setTopBtn(true);
  //   }
  // }, [page]);

  // const clickTopBtn = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   setTopBtn(false);
  // }

  // useEffect(() =>{
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //   });
  // },[])

  const renderHeader = () => {
    if (isMobile) return <HeaderM />;
    return <Header menu={1} />;
  };

  return (
    <>
      <div className="secondUp">
        {renderHeader()}
        <Company type="career" />
      </div>
      {!isMobile ? <Footer /> : <FooterM />}
    </>
  );
};

export default Index;
