import { useState, useEffect } from 'react';
import {
  useHistory,
  Link,
  useLocation,
} from 'react-router-dom';
import Cookies from 'js-cookie';

import logo from '../../assets/images/logo.jpg';
import tMenuX from '../../assets/images/t_menu_x.png';
import mLogoWide from '../../assets/images/logo_m.png';

import styles from './sideMenu_m.module.scss';

const SideMenuM = ({ loginInfo, onClickSideMenu }) => {
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn, userInfo } = loginInfo;
  const [allRequestCount, setAllRequestCount] = useState(0); // 신청현황 수
  const [statusProcessCount, setStatusProcessCount] =
    useState(0); // 진행현황 수
  const [isNewThreadExist, setIsNewThreadExist] =
    useState(false); // 완료목록 새글여부
  const [
    isCounselNewThreadExist,
    setIsCounselNewThreadExist,
  ] = useState(false); // 간편상담 새글여부

  const menus = [
    {
      id: 1,
      name: '회사소개',
      url: '/masterContents',
      children: [
        {
          name: '회사소개',
          url: '/company/introduce',
        },
        {
          name: '뉴스/자료',
          url: '/company/news',
        },
        {
          name: '상시채용',
          url: '/company/career',
        },
      ],
    },
    {
      id: 2,
      name: '서비스',
      url: '/masterContents',
      children: [
        {
          name: '변호사 선임',
          url: '/services/lawyer',
        },
        {
          name: '자문서',
          url: '/services/jamoon',
        },
        {
          name: '소송비욜대출',
          url: '/services/loan',
        },
        {
          name: '승소 가능성 진단',
          url: '/services/diagnosis',
        },
      ],
    },
    {
      id: 3,
      name: 'CONTACT US',
      url: '/masterPayment',
      children: [
        {
          name: '투자 문의',
          url: '/contact/invest',
        },
        {
          name: '사업 연계',
          url: '/contact/link',
        },
        {
          name: '서비스 문의',
          url: '/contact/service',
        },
      ],
    },
  ];

  const handleClick = (event) => {
    const tgl = event.currentTarget.nextSibling;
    const arr = event.currentTarget.childNodes[1];
    // console.log(tgl);
    tgl.classList.toggle('mystyle');
    if (tgl.classList.contains('mystyle')) {
      tgl.style.display = 'block';
      // arr.style.transform = "rotate(180deg)";
    } else {
      tgl.style.display = 'none';
      // arr.style.transform = "rotate(360deg)";
    }
  };

  const onClickLogin = () => {
    onClickSideMenu();
    history.push('/re_login');
  };

  const onClickMenu = (e) => {
    const { pathname } = e.currentTarget.dataset;
    onClickSideMenu();
    history.push(`/${pathname}`);
    return undefined;
  };

  const onClickCsMenu = (e) => {
    const { pathname } = e.currentTarget.dataset;
    onClickSideMenu();
    history.push(`/${pathname}`);
  };

  const onClickSignup = () => {
    if (isLoggedIn) {
      if (userInfo?.info === 'client') {
        // alert("준비중입니다.");
        history.push('/requestStatusClient');
      } else {
        history.push('/userInfoEdit');
      }
    } else {
      history.push('/signupClient');
    }
  };

  const onClickLawyerChat = () => {
    if (isLoggedIn) {
      if (userInfo?.info === 'client')
        history.push('/clientChatList');
      else history.push('/lawyerChatList');
    } else {
      history.push('/re_signupLawyerIntro');
    }
  };

  useEffect(() => {
    // getServiceListClient();
    // getProcessStatusCount();
    // getServiceStatusTypeList();
    // checkCounselNewThreadExist();
  }, []);

  return (
    <div className={styles.bg}>
      <div className={styles.sideMenu}>
        <div className={styles.menuWrapper}>
          <div className={styles.buttonsWrapper}>
            <button type="button">
              <img src={mLogoWide} alt="logo" />
            </button>
            <button type="button" onClick={onClickSideMenu}>
              <img src={tMenuX} alt="close" />
            </button>
          </div>
          <div className={styles.itemWrapper}>
            <ul className={styles.menuContainer}>
              {menus.map((value, idx) => {
                return (
                  <li
                    key={value.id}
                    className={styles.upperList}
                  >
                    <button
                      type="button"
                      onClick={(e) => {
                        // setVisible(!visible);
                        handleClick(e);
                      }}
                    >
                      <span> {value.name}</span>
                    </button>
                    <ul
                      className={
                        location.pathname.search(value.url)
                          ? `${styles.dropMenu}`
                          : `${styles.dropMenuAfter} mystyle`
                      }
                    >
                      {value.children.map((item, index) => {
                        const { url, name } = item;
                        return (
                          <li
                            className={styles.linkBtn}
                            key={index}
                          >
                            <button
                              onClick={(e) => {
                                // setVisible(!visible);
                                // listClick(e);
                                // setLink(url);
                              }}
                              type="button"
                            >
                              <Link
                                to={url}
                                className={
                                  location.pathname.search(
                                    url
                                  )
                                    ? `${styles.linkBtn}`
                                    : `${styles.linkBtnAfter}`
                                }
                              >
                                - {name}
                              </Link>
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenuM;
