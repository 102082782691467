import mockImg from '../../assets/images/mockImg.png';
import mockImg2 from '../../assets/images/mockImg2.png';
import mockImg3 from '../../assets/images/mockImg3.png';
import mockImg4 from '../../assets/images/mockImg4.png';
import mockImg5 from '../../assets/images/mockImg5.png';
import mockImg6 from '../../assets/images/mockImg6.png';
import mockImg7 from '../../assets/images/mockImg7.png';
import mockImg8 from '../../assets/images/mockImg8.png';

export const POST_DATA = [
  {
    id: 1,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 2,
    title: '2022년 새해 복 많이 받으세요',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg2,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 3,
    title: '신년 바뀌는 법을 안내해 드립니다.',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg3,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 4,
    title: '22년 2월 로스닥-XR을 런칭합니다.',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg4,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 5,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg5,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 6,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg6,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 7,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg7,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 8,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg8,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 9,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg2,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 10,
    title: '2022년 새해 복 많이 받으세요',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg4,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 11,
    title: '신년 바뀌는 법을 안내해 드립니다.',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg3,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 12,
    title: '22년 2월 로스닥-XR을 런칭합니다.',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 13,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg6,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 14,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg2,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 15,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg7,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 16,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg8,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 17,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 18,
    title: '2022년 새해 복 많이 받으세요',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg4,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 19,
    title: '신년 바뀌는 법을 안내해 드립니다.',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg3,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 20,
    title: '22년 2월 로스닥-XR을 런칭합니다.',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg7,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 21,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg5,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 22,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg8,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 23,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg2,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 24,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg8,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 25,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg6,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 26,
    title: '2022년 새해 복 많이 받으세요',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg2,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 27,
    title: '신년 바뀌는 법을 안내해 드립니다.',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg8,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 28,
    title: '22년 2월 로스닥-XR을 런칭합니다.',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg5,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 29,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 30,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg6,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 31,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 32,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg3,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 33,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg7,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 34,
    title: '2022년 새해 복 많이 받으세요',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg5,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 35,
    title: '신년 바뀌는 법을 안내해 드립니다.',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 36,
    title: '22년 2월 로스닥-XR을 런칭합니다.',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg8,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 37,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg7,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 38,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg6,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 39,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg2,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 40,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg4,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 41,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg2,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 42,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg7,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 43,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg4,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 44,
    title: '2022년 새해 복 많이 받으세요',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg8,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 45,
    title: '신년 바뀌는 법을 안내해 드립니다.',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 46,
    title: '22년 2월 로스닥-XR을 런칭합니다.',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg6,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 47,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      'enectus et netus et malesenectus et netus et malesuada. Nunc pulvinar sapien et ligula ullamcorper malesuada proin. Neque convallis a cras semper',
    image: mockImg7,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 48,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg2,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 49,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 50,
    title: '국민의 보호 받을 권리를 위해',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg3,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
];

export const NEWS_DATA = [
  {
    id: 1,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      '2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요',
    image: mockImg,
    writer: '로스닥운영팀',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 2,
    title:
      '2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg2,
    writer: '로스닥운영팀',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 3,
    title: '신년 바뀌는 법을 안내해 드립니다.',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg3,
    writer: '로스닥운영팀',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 4,
    title: '22년 2월 로스닥-XR을 런칭합니다.',
    content:
      'Nisi scelerisque euquisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.',
    image: mockImg4,
    writer: 'test',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
];

export const NEWS_DATA_M = [
  {
    id: 1,
    title:
      '국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.국민의 보호 받을 권리를 위해 (주)록션이 도움을 드리겠습니다.',
    content:
      '2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요',
    image: mockImg,
    writer: '로스닥운영팀',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 2,
    title:
      '2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요2022년 새해 복 많이 받으세요',
    content:
      'Elit at imperdietplatea dictumst quisque sagittis purus. Pulvinar elementum integer',
    image: mockImg2,
    writer: '로스닥운영팀',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
  {
    id: 3,
    title: '신년 바뀌는 법을 안내해 드립니다.',
    content:
      'accumsan tortor posuere. Ut sem nulla pharetra diam. Fames ac turpis egestas maecenas. Bibendum neque',
    image: mockImg3,
    writer: '로스닥운영팀',
    type: '공지사항',
    news: 'undefined',
    createdAt: '2022-11-29T23:50:10.000Z',
    updatedAt: '2022-11-29T23:50:10.000Z',
  },
];
