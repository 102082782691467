import React, { useState, useEffect } from 'react';

import DownSection from './downSection';
import UpSection from './upSection';
import mainLayer from '../../assets/images/mainLayer.png';
import map from '../../assets/images/map.jpg';

import styles from './style.module.scss';
import '../../reset/reset.css';

const Index = ({ type }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(type);
  }, [type]);

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {}, []);

  return (
    <div className={styles.main}>
      <UpSection />
      {/* <DownSection/> */}
    </div>
  );
};

export default Index;
