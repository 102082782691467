import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import mainLayer from '../../../assets/images/mainLayer.png';
import map from '../../../assets/images/map.jpg';

import styles from './style.module.scss';
import '../../../reset/reset.css';

const Index = ({ type }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(type);
  }, [type]);

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {}, []);

  return (
    <div className={styles.main}>
      <div>
        <p className={styles.mainTitle}>
          <span>로스닥</span>의 서비스는 다릅니다.
        </p>
        <p className={styles.subTitle}>
          (주)록션의 법률 서비스 플랫폼 로스닥은 차별화된
          변호사 선임 시스템을 개발하여 제공 함으로써,
          의뢰인과 변호사 모두가 만족할 수 있는 서비스
          제공하고자 합니다.
        </p>
      </div>
      <div>
        <img
          className={styles.mainImg}
          src={mainLayer}
          alt="qr"
        />
      </div>
    </div>
  );
};

export default Index;
