import React, { useState, useEffect, useMemo } from 'react';
// import { Link } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';

import getMasterNewsList from '../../services/getMasterNewsList';
// import styles from "./style.module.scss";
// import  "./re_header.scss";
// import "../../reset/reset.css"
import styles from './careerList.module.scss';
import './re_pager.scss';
import formatDate from '../../utils/formatDate';
// import Pagination from "../pagination/pagination";
import Pagination from 'react-js-pagination';

import map from '../../assets/images/map.jpg';
import logo from '../../assets/images/logoIn.jpg';
import {
  NEWS_DATA,
  ENGINEER_DATA,
  MARKETING_DATA,
  CAREER_DATA
} from '../company/mockData';

const Index = () => {
  // const { params } = useParams();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  const [curPage, setCurPage] = useState(1);
  // const [allPageCount, setAllPageCount] = useState(0);
  // const [news, setNews] = useState([]);
  const [news, setNews] = useState(CAREER_DATA);
  const [allPageCount, setAllPageCount] = useState(1);
  const [Type, setType] = useState('정규직');

  const PageSize = 10; // 보여지는 페이지 수 조절
  const currentTableData = useMemo(() => {
    const firstPageIndex = (curPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return news.slice(firstPageIndex, lastPageIndex);
  }, [curPage, news]);

  const handlePageChange = (page) => {
    setCurPage(page);
    // if (isMobile) {
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     // behavior: "smooth",
    //   });
    // } else {
    //   window.scrollTo({
    //     // top: `${PagerTop}`,
    //     top: 832,
    //     left: 0,
    //     // behavior: "smooth",
    //   });
    // }
  };

  const handleLink = (data) => {
    history.push({
      pathname: `/company/career/detail`,
      // pathname: `/company/introduce`,
      state: {
        data,
      },
    });
  };
  return (
    <div className={styles.companyNews}>
      <div className={styles.noticeListWrapper}>
        <div
          className={`${styles.tableWrapper} ${styles.flexColumn}`}
        >
          <div
            className={`${styles.tableHeaderWrapper} ${styles.flexRow} ${styles.mobileOff}`}
          >
            <div
              className={`${styles.infoBox} ${styles.flexRow}`}
            >
              <div className={styles.no}>NO</div>
              <div
                className={`${styles.title} ${styles.underLineNone}`}
              >
                제목
              </div>
              <div className={styles.others}>모집분야</div>
              <div className={styles.others}>채용형태</div>
              <div className={styles.date}>모집기간</div>
              <div className={styles.others}>진행상태</div>
            </div>
          </div>
          {currentTableData &&
          currentTableData?.length > 0 ? (
            // currentTableData?.map(({ id, title, category,type,date,status,createdAt }) => (
            currentTableData?.map((item) => (
              <div
                key={item?.id}
                className={`${styles.rowWrapper} ${styles.flexColumn}`}
                onClick={() => handleLink(item)}
              >
                <div
                  className={`${styles.infoBox} ${styles.flexRow}`}
                >
                  <div className={styles.no}>
                    {item?.id}
                  </div>
                  {/* <div className={styles.bar}>|</div> */}
                  <div
                    className={`${styles.title} ${styles.textStart} ${styles.ellipsis} ${styles.mobileOff}`}
                    // onClick={onClickRow}
                    data-index={item?.id}
                    role="button"
                    tabIndex={0}
                  >
                    {item?.title}
                  </div>
                  {/* <div className={styles.date}>{formatDate(createdAt)}</div> */}
                  <div className={styles.others}>
                    {item?.category}
                  </div>
                  <div className={styles.others}>
                    {item?.type}
                  </div>
                  <div className={styles.date}>
                    {item?.date}
                  </div>
                  <div className={styles.others}>
                    <button
                      className={
                        item?.status === '모집중'
                          ? styles.working
                          : styles.ending
                      }
                    >
                      {item?.status}
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.empty}>
              작성된 글이 없습니다.
            </div>
          )}
        </div>
      </div>
      <div
        className="winPage"
        style={{ marginTop: '3rem' }}
      >
        <Pagination
          // onChange={handlePageChange}

          pageSize={PageSize}
          activePage={curPage}
          itemsCountPerPage={6}
          totalItemsCount={news?.length}
          // totalItemsCount={18}
          pageRangeDisplayed={10}
          prevPageText="‹"
          nextPageText="›"
          onChange={handlePageChange}
          itemClassPrev="prevBtn"
          itemClassNext="nextBtn"
        />
      </div>
    </div>
  );
};

export default Index;
