import './re_footer.scss';
import '../../reset/reset.css';
import footerImg from '../../assets/images/footer_img.png';
import footerQr from '../../assets/images/footer_qr.png';
import footerLogo from '../../assets/images/footer_logo.png';
import facebook from '../../assets/images/facebook.png';
import instagram from '../../assets/images/instagram.png';
import twitter from '../../assets/images/twitter.png';

const ReFooter = () => {
  // const onClickGooglePlay = () => {
  //   window.open(
  //     `https://lawsdaq.s3.ap-northeast-2.amazonaws.com/etc/lawsdaq.apk`,
  //     "_blank"
  //   );
  // };

  return (
    <footer className="footer_container">
      <ul className="footer_cover cf">
        <li className="footer_name">LAWCTION</li>
        <li>
          <ul className="footer_menu">
            <li className="footer_menu_first">
              <span>
                {' '}
                <span>T. </span>02 6952 2019
              </span>
              <span>
                {' '}
                <span>F. </span>02 6952 2019
              </span>
              {/* <span> <span>M. </span>OZZYBACH@lawction.net</span> */}
              <span>
                {' '}
                <span>M. </span>lawsdaq@lawction.net
              </span>
            </li>
            <li className="footer_menu_sec">
              <p>
                <span>A. </span>서울시 강남구
                선릉로555(역삼동) 선릉빌딩 204호
              </p>
            </li>
            <li>
              <p>
                COPYRIGHT (C) 2022. ALL RIGHTS RESERVED
                LAWCTION CO., LTD{' '}
              </p>
            </li>
          </ul>
        </li>
        <li>
          <ul className="social_menu">
            <li>
              <a href="https://ko-kr.facebook.com/">
                <img src={facebook} alt="페이스북 가기" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/">
                <img
                  src={instagram}
                  alt="인스타그램 가기"
                />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/?lang=ko">
                <img src={twitter} alt="트위터 가기" />
              </a>
            </li>
            <li className="qr_img">
              <img src={footerQr} alt="qr" />
            </li>
          </ul>
        </li>
      </ul>
    </footer>
  );
};

export default ReFooter;