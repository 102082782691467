import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import styles from "./style.module.scss";
import './re_header.scss';
import '../../reset/reset.css';

const Index = ({ menu }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [title, setTitle] = useState('');
  const url = process.env.REACT_APP_URL;

  // useEffect(() => {
  //   setTitle(menu);
  // }, [menu]);
  
  const onClickSidebar = () => {
    setOpenSidebar((prev) => !prev);
  };

  const [fixedNavMenu, setFixedNavMenu] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (scrollPosition > 185) {
      setFixedNavMenu('fix');
    } else {
      setFixedNavMenu('');
    }
  }, [scrollPosition]);

  // const onClickNavMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
  const onClickNavMenu = (e) => {
    const { navMenu } = e.currentTarget.dataset;
    // history.push(`/${navMenu}`);
  };

  return (
    <div className="hedaerBox">
      <div className="hedaerContainer">
        <div className="hedaerLeft">
          <Link to="/">
            <p>LAWCTION</p>
          </Link>
        </div>
        {/* <nav className={`navbar ${fixedNavMenu}`}> */}
        {/* <nav className={`navbar${fixedNavMenu}`}> */}
        <nav className="navbar">
          <div className="dropdown">
            <button
              type="button"
              // className="dropbtn"
              className={
                menu === 1 ? `dropbtn sel` : `dropbtn`
              }
              data-nav-menu="re_summaryMain"
              onClick={onClickNavMenu}
            >
              회사소개
            </button>
            <button
              type="button"
              className={
                menu === 2 ? `dropbtn sel` : `dropbtn`
              }
              data-nav-menu="re_jmMain"
              onClick={onClickNavMenu}
            >
              서비스
            </button>
            <button
              type="button"
              className={
                menu === 3 ? `dropbtn sel` : `dropbtn`
              }
              data-nav-menu="re_gsMain"
              onClick={onClickNavMenu}
            >
              CONTACT US
            </button>

            {/* <div className={`dropdown-content ${fixedNavMenu}`}> */}
            <div
              className={`dropdown-content ${fixedNavMenu}`}
            >
              <section>
                <div className="dropRight">s</div>
                <div className="row">
                  <div className="column">
                    <Link to="/company/introduce">
                      &middot; 회사소개
                    </Link>
                    <Link to="/company/news">
                      &middot; 뉴스/자료
                    </Link>
                    <Link to="/company/career">
                      &middot; 상시채용
                    </Link>
                  </div>
                  <div className="column">
                    <Link
                      to={{ pathname: '/services/lawyer' }}
                    >
                      &middot; 변호사 선임
                    </Link>
                    <Link
                      to={{ pathname: '/services/jamoon' }}
                    >
                      &middot; 자문서
                    </Link>
                    <Link
                      to={{ pathname: '/services/loan' }}
                    >
                      &middot; 소송비용대출
                    </Link>
                    <Link
                      to={{
                        pathname: '/services/diagnosis',
                      }}
                    >
                      &middot; 승소 가능성 진단
                    </Link>
                  </div>
                  <div className="column">
                    {/* <Link>&middot; 준비중</Link> */}
                    {/* <Link>&middot; 준비중</Link> */}
                    <Link
                      to={{ pathname: '/contact/invest' }}
                    >
                      &middot; 투자 문의
                    </Link>
                    <Link
                      to={{ pathname: '/contact/link' }}
                    >
                      &middot; 사업 연계
                    </Link>
                    <Link
                      to={{ pathname: '/contact/service' }}
                    >
                      &middot; 서비스 문의
                    </Link>
                  </div>
                </div>
                <div className="dropLeft">s</div>
              </section>
            </div>
          </div>
        </nav>
        <div className="hedaerRight">
          <Link
            to={{ pathname: 'https://lawsdaq.com/' }}
            target="_blank"
          >
            <button type="button" className="firstBtn">
              LAWSDAQ 바로가기
            </button>
          </Link>
          {/* <button
            type="button"
            className="secBtn"
            >
            <Link to={{ pathname: "https://lawsdaq.com/lawsuitLoanMain" }} target="_blank">
               소송비용 대출연계 바로가기
            </Link>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Index;
