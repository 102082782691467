import styles from './footer_m.module.scss';
import facebook from '../../assets/images/facebook.png';
import instagram from '../../assets/images/instagram.png';
import twitter from '../../assets/images/twitter.png';

const Index = () => {
  return (
    <>
      <div className={styles.footerWrapper}>
        <ul className={styles.socialWrapper}>
          <li>
            <a href="https://ko-kr.facebook.com/">
              <img src={facebook} alt="페이스북 가기"></img>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/">
              <img
                src={instagram}
                alt="인스타그램 가기"
              ></img>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/?lang=ko">
              <img src={twitter} alt="트위터 가기"></img>
            </a>
          </li>
        </ul>
        <ul className={styles.contactWrapper}>
          <li>
            <span>T</span> 02 6952 2019
          </li>
          <li>
            <span>F</span> 02 070 8255 2019
          </li>
          <li>
            <span>M</span> ozzybach@lawction.net
          </li>
          <li>
            <span>A</span> 서울시 강남구 선릉로
            555(선릉빌딩) 204호
          </li>
        </ul>
      </div>
      <div className={styles.lawsdaqBtnWrapper}>
        <button
          type="button"
          onClick={() => {
            window.location.href = 'https://lawsdaq.com/';
          }}
        >
          <p>LAWSDAQ 홈페이지 바로가기</p>
          {/* <p>LAWSDAQ 홈페이지</p>
          <p>바로가기</p> */}
        </button>
      </div>
    </>
  );
};

export default Index;
