import React, { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';

import getMasterNewsList from '../../services/getMasterNewsList';
// import styles from "./style.module.scss";
// import  "./re_header.scss";
// import "../../reset/reset.css"
import styles from './company.module.scss';
import formatDate from '../../utils/formatDate';
import Pagination from '../pagination/pagination';
import PageNation from '../pagination/pageNation';
import Career from '../career';
import CareerList from '../career/careerList';
import CareerDetail from '../career/detail';

import map from '../../assets/images/map.jpg';
import logo from '../../assets/images/logoIn.jpg';
import {
  NEWS_DATA,
  ENGINEER_DATA,
  MARKETING_DATA,
} from './mockData';

const Index = ({ type }) => {
  // const { params } = useParams();
  const location = useLocation();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [title, setTitle] = useState('introduce');

  const [curPage, setCurPage] = useState(1);
  // const [allPageCount, setAllPageCount] = useState(0);
  // const [news, setNews] = useState([]);
  const [news, setNews] = useState(NEWS_DATA);
  const [allPageCount, setAllPageCount] = useState(1);
  const [Type, setType] = useState('정규직');

  const [textCount, setTextCount] = useState(0);
  const [secondCategory, setSecondCategory] = useState([]); // 두번째 셀렉터 초기값

  const [userInput, setUserInput] = useState({
    title: '',
    name: '',
    phone: '',
    email: '',
    content: '',
    type: '정규직',
    caseType1: '',
    caseType2: '',
    // file: "",
    // fileName: "",
  });

  const selCategoryList = [
    // "필수항목 (대분류)",
    '정규직',
    '계약직',
    '파견직',
  ];
  const selAppyList = [
    '필수항목 (대분류)',
    '개발',
    '마케팅',
  ];

  let words = location.pathname.split('/');
  useEffect(() => {
    if (location) {
      switch (words[2]) {
        case 'introduce':
          setTitle('introduce');
          break;
        case 'news':
          setTitle('news');
          break;
        case 'career':
          if (words[3] === 'detail') {
            setTitle('careerDetail');
            return;
          }
          setTitle('career');
          break;
        default:
          break;
      }
    }
  }, [location]);


  // useEffect(() => {
  //   setTitle(type);
  // }, []);

  // const getListAll = async () => {
  //   const res = await getMasterNewsList(curPage);
  //   const { status, data } = res;

  //   if (status === 200) {
  //     // setNews(arraySortByUpdateTimeDescOrder(data?.list));
  //     setNews(data?.list);
  //     setAllPageCount(data?.page);
  //   }
  // };

  // useEffect(() => {
  //   getListAll();
  // }, [curPage]);

  useEffect(() => {
    // 대분류에 따른 소분류 값
    // switch (caseType1 || String(values?.type).split(">")[0]) {
    // setSecondCategory(ENGINEER_DATA)
    switch (userInput?.caseType1) {
      // case "":
      //   console.log("빈값");
      //   setSecondCategory();
      //   break;
      case '개발':
        setSecondCategory(ENGINEER_DATA);

        break;
      case '마케팅':
        setSecondCategory(MARKETING_DATA);

        break;
      default:
        break;
    }
  }, [userInput]);

  const onClickCategory = (e) => {
    const { mainType } = e.currentTarget.dataset;
    setTitle(mainType);
  };

  const onChangeInput = (e) => {
    // eslint-disable-next-line
    const { name, value } = e.currentTarget;
    setUserInput({ ...userInput, [name]: value });
    countText(name, value.length);
  };

  const countText = (inputName, inputLength) => {
    if (inputName === 'content') {
      setTextCount(inputLength);
    }
  };

  // const onChangeFile = (e) => {
  //   if (e.target.files !== null) {
  //     const file = e.target.files[0];
  //     const nn = e.target.name;
  //     console.log(nn);
  //     setUserInput({ ...userInput, file, [nn]: file.name });
  //   }
  // };

  const onChangeFile = (e) => {
    // if (e.target.files !== null) {
    const file = e.target.files[0];
    const nn = e.target.name;
    const nn2 = `${e.target.name}2`;
    setUserInput({
      ...userInput,
      [e.target.name]: file,
    });
    // }
  };

  const changeSelectOne = (value) => {
    setUserInput({ ...userInput, type: value });
    // setCaseType1(value);
    // setCaseType2("");
  };

  const changeSelectApply = (value) => {
    setUserInput({ ...userInput, caseType1: value });
    // setCaseType1(value);
    // setCaseType2("");
    // userInput.caseType1 = "";
  };

  const changeSelectApplyTwo = (value) => {
    // setUserInput({ ...userInput, caseType2: value.trim()});
    setUserInput({ ...userInput, caseType2: value });
    // setCaseType2(value);
    // setCaseTypeEtc("");
    // userInput.caseType2 = "";
  };

  const onClickSubmit = () => {
    if (
      userInput?.name === '' ||
      userInput?.phone === '' ||
      userInput?.caseType1 === '' ||
      userInput?.caseType2 === '' ||
      userInput?.introd === '' ||
      userInput?.portf === '' ||
      userInput?.resume === '' ||
      userInput?.type === ''
    ) {
      alert('빈 칸을 모두 입력해 주세요.');
      return;
    }
    alert('현재 지원하지 않는 서비스 입니다.');
    // insertPartnershipContent();
  };

  const insertPartnershipContent = async () => {
    // const res = await customerCenterPartnershipAPI(userInput);
    // if (res.status === 200) {
    //   alert("제휴 문의가 등록되었습니다.");
    //   window.location.reload();
    // }
  };


  return (
    <div className={styles.companySection}>
      <h1>COMPANY</h1>
      <ul className={styles.companyMenu}>
        <li>
          <button
            type="button"
            data-main-type="introduce"
            onClick={onClickCategory}
            className={
              title === 'introduce'
                ? styles.active
                : styles.off
            }
          >
            회사소개
          </button>
        </li>
        <li>
          <button
            type="button"
            data-main-type="news"
            onClick={onClickCategory}
            className={
              title === 'news' ? styles.active : styles.off
            }
          >
            뉴스/자료
          </button>
        </li>
        <li>
          <button
            type="button"
            data-main-type="career"
            onClick={onClickCategory}
            className={
              title === 'career'
                ? styles.active
                : styles.off || title === 'careerDetail'
                ? styles.active
                : styles.off
            }
          >
            상시채용
          </button>
        </li>
      </ul>
      <div className={styles.companyBox}>
        {title === 'introduce' && (
          <div className={styles.companyIntro}>
            <div className={styles.contentsLeft}>
              <pre>
                2020년 11월에 설립된 ㈜록션은 의뢰인이 법률
                서비스를 받고자 할 때 느낄 수 있는 불합리한
                요소를 최소화하고 최대한 합리적인 서비스를
                받을 수 있도록 법률서비스 플랫폼 로스닥을
                개발하게 되었습니다.
              </pre>
              <pre>
                의뢰인에게 꼭 필요한 변호사, 법의 보호가
                필요한 의뢰인 등 모두에게 필요하고 좋은
                서비스가 되도록, 늘 귀 기울이며, 발전하는
                ㈜록션의 법률플랫폼 로스닥이 되겠습니다.
                감사합니다.
              </pre>

              <div className={styles.briefRequestContent}>
                <h1>회사 개요</h1>
                <div
                  className={`${styles.tableWrapper} ${styles.flexColumn}`}
                >
                  <dl>
                    <div
                      className={`${styles.tableHeaderWrapper} ${styles.flexRow}`}
                    >
                      <dt>회사명</dt>
                      <dd className={styles.ellipsis}>
                        주식회사 록션
                      </dd>
                    </div>
                    <div className={styles.flexRow}>
                      <dt>설립일</dt>
                      <dd>2020년 11월</dd>
                    </div>
                    <div className={styles.flexRow}>
                      <dt>홈페이지</dt>
                      <dd>https://www.lawsdaq.com</dd>
                    </div>
                    <div className={styles.flexRow}>
                      <dt>주소</dt>
                      <dd>
                        서울시 강남구 선정릉로 555(역삼동)
                        선릉빌딩 204호
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
            <div className={styles.contentsRight}>
              <div className={styles.contentsLogo}>
                <img
                  className={styles.contentsRightImg}
                  src={logo}
                  alt="map"
                />
              </div>
              <img src={map} alt="map" />
              <p>
                <span className={styles.contentsGreen}>
                  {' '}
                  2호선 선릉역
                </span>{' '}
                7번 출구 선정릉역 방향 500미터
              </p>
              <p>
                <span className={styles.contentsRed}>
                  수인분당선 선정릉역
                </span>{' '}
                4번 출구 선릉역방향 200미터
              </p>
            </div>
          </div>
        )}
        {title === 'news' && (
          <div className={styles.companyNews}>
            <div className={styles.noticeListWrapper}>
              <div
                className={`${styles.tableWrapper} ${styles.flexColumn}`}
              >
                <div
                  className={`${styles.tableHeaderWrapper} ${styles.flexRow} ${styles.mobileOff}`}
                >
                  <div
                    className={`${styles.infoBox} ${styles.flexRow}`}
                  >
                    <div className={styles.no}>NO</div>
                    <div
                      className={`${styles.title} ${styles.underLineNone}`}
                    >
                      제목
                    </div>
                    <div className={styles.date}>
                      등록일
                    </div>
                  </div>
                </div>
                {news && news?.length > 0 ? (
                  news?.map(({ id, title, createdAt }) => (
                    <div
                      key={id}
                      className={`${styles.rowWrapper} ${styles.flexColumn}`}
                    >
                      <div
                        className={`${styles.infoBox} ${styles.flexRow}`}
                      >
                        <div className={styles.no}>
                          {id}
                        </div>
                        <div className={styles.bar}>|</div>
                        <div
                          className={`${styles.title} ${styles.textStart} ${styles.ellipsis} ${styles.mobileOff}`}
                          // onClick={onClickRow}
                          data-index={id}
                          role="button"
                          tabIndex={0}
                        >
                          {title}
                        </div>
                        <div className={styles.date}>
                          {formatDate(createdAt)}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles.empty}>
                    작성된 글이 없습니다.
                  </div>
                )}
              </div>
            </div>
            <div className={styles.grid}>
              <Pagination
                allPageCount={allPageCount}
                curPage={curPage}
                setCurPage={setCurPage}
              />
              {/* <PageNation
              data={NEWS_DATA}
              // allPageCount={xmlData?.PrecSearch?.totalCnt}
              allPageCount={allPageCount}
              curPage={curPage}
              setCurPage={setCurPage}
            /> */}
            </div>
          </div>
        )}
        {title === 'career' && (
          <>
            {/* <Career /> */}
            <CareerList/>
          </>
        )}
        {title === 'careerDetail' && (
          <>
            <CareerDetail />
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
